interface HeadOption {
  title?: string;
  description?: string;
  image?: string;
  category?: string;
}

export const useFormattedHead = (option: HeadOption = {}) => {
  const $img = useImage();
  const route = useRoute();
  const { locale } = useI18n();
  let title = option.title || "Architect Studio Kim";
  if (title !== "Architect Studio Kim") {
    title = `${title} | Architect Studio Kim`;
  }
  const description = option.description;
  const domain = "ask-archi.com";
  const image = $img.getSizes(
    option.image?.replace(/\?.*/g, "") ||
      `https://images.prismic.io/ask-portfolio/Z78N6p7c43Q3gQYe_ask-logo.png`,
    {
      sizes: "xs:50vw",
      modifiers: {
        quality: 80,
      },
    },
  ).src;

  const url = `https://www.${domain}${route.path}`;
  const link = [
    {
      rel: "canonical",
      href: url,
    },
  ];
  const meta = [
    {
      name: "description",
      content: description,
    },
    {
      property: "og:site_name",
      content: "Architect Studio Kim",
    },
    {
      property: "og:image",
      content: image,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:url",
      content: url,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:image",
      content: image,
    },
  ];

  return {
    htmlAttrs: {
      lang: locale.value,
    },
    title,
    description,
    link,
    meta,
  };
};
